import React from "react";
import Link from "next/link";
import { ImageDefault } from "@components/utils/Utils";
import { STATIC_LANDING_PAGES_URLS } from "@components/utils/constants";
import { IImageComponentResponse } from "@customTypes/componentResponses";
import ImageCDN from "@components/skeleton/ImageCDN";

interface IFooterTripadvisorLogoProps {
  tripadvisorLogo: IImageComponentResponse;
}

const FooterTripadvisorLogo = ({
  tripadvisorLogo,
}: IFooterTripadvisorLogoProps): JSX.Element => {
  return (
    <Link href={STATIC_LANDING_PAGES_URLS.REVIEWS}>
      <ImageCDN
        src={ImageDefault(tripadvisorLogo)}
        width={148}
        height={149}
        layout="fixed"
        alt="tripadvisor_award"
      />
    </Link>
  );
};

export default FooterTripadvisorLogo;
